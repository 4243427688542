.chip {
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: normal;
  font-size: 13px;
  color: $color-white;
  line-height: 51px;
  font-family: $font-poppins;
  padding: 8px 12px;
}
