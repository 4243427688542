.card {
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
}

.card-line-chart {
  width: 275px;
  height: 194px;
  background-color: $background-color-warm;
  border: 1px solid $border-color;
  border-radius: 0.625em;
  padding-bottom: 20px;
  background: linear-gradient(
    $background-color-warm,
    $background-color-secondary
  );

  .card-body {
    height: 118px;
    padding: 1em;

    .card-content {
      display: flex;
      padding: 0.25em;

      .card-icon {
        width: 52px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $background-color-primary;

        img {
          width: 26px;
        }
      }

      .card-title {
        display: flex;
        flex-direction: column;
        margin-left: 0.75em;

        span {
          color: $color-primary;
          font-family: $font-poppins;
          font-style: normal;

          &:first-child {
            font-size: 14px;
            font-weight: 300;
            line-height: 1.75em;
            text-transform: uppercase;
          }

          &:last-child {
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.5em;
          }
        }
      }
    }
  }

  .card-action {
    height: 70px;
  }
}

.card-filled-line-chart {
  width: 408px;
  height: 400px;
  background: linear-gradient(
    180deg,
    $background-color-primary 0%,
    $background-color-secondary 100%
  );
  border: 1px solid $color-accent;
  box-sizing: border-box;
  border-radius: 0.625em;

  .card-body {
    height: 138px;
    display: flex;
    padding: 2em;

    span {
      font-family: $font-poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.75em;
      color: $color-accent;
    }
  }

  .card-action {
    height: 262px;
    display: flex;
  }
}
