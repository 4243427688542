$color-primary: #cecece;
$color-secondary: #550101;
$color-accent: #ffc000;
$color-warm: #566176;
$color-dark: #15171b;
$color-white: #ffffff;
$color-light: #ffffff66;
$color-tab: #1b0000;

$background-color-primary: #0a0b0d;
$background-color-secondary: #15171b;
$background-color-dark: #0c0e11;
$background-color-light: #212530;
$background-color-warm: #0e1013;
$background-color-accent: #ffc000;

$font-poppins: 'Poppins';

$border-color: #1c1f27;
$border-color-secondary: #2c2e32;
